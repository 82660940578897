import React from "react";
import style from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div>Gia Huy Le © 2022</div>
    </div>
  );
};

export default Footer;
